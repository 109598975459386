<template>
  <ContentBlock class="analytics-tab">
    <template #default="{ scrolledToTop }">
      <SlOverlay :show="isLoading" />

      <div
        class="analytics-tab__header"
        :class="{
          'analytics-tab__header--scrolled': !scrolledToTop
        }"
      >
        <div class="analytics-tab__header-title heading-3-sb">
          {{ activeDashboard.name }}
        </div>
        <div
          v-if="$sl_tabEditable"
          class="analytics-tab__header-actions"
        >
          <SlButton
            variant="secondary"
            color="grey"
            @click="handleCrateWidget"
          >
            <template #prepend>
              <icon
                data="@icons/plus.svg"
                class="fill-off size-16 color-grey-70"
              />
            </template>

            {{ $t('Web.Dashboard.AddWidget') }}
          </SlButton>
          <DashboardActionsDropdown
            :dashboard="activeDashboard"
            :actions="activeDashboard.actions"
          />
        </div>
      </div>
      <transition name="fade">
        <div
          v-if="!isLoading"
          class="analytics-tab__content"
        >
          <template v-if="!isTabEmpty">
            <SectionItem
              v-for="(section, sectionIndex) in sections"
              :key="sectionIndex"
              :section="section"
              :index="sectionIndex"
              :widgets="widgets"
              :disabled="!$sl_tabEditable"
            />
          </template>
          <SlNoData v-else>
            <template #image>
              <icon
                data="@icons/illustration/empty-сhart.svg"
                class="illustration-md"
              />
            </template>
            <template #title>
              {{ $t('Web.Dashboard.NoDataTitle') }}
            </template>
            <template #text>
              {{ $t('Web.Dashboard.NoDataText') }}
            </template>
          </SlNoData>
        </div>
      </transition>
    </template>
  </ContentBlock>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import DashboardActionsDropdown from '@/components/Dashboard/Analytics/DashboardActionsDropdown.vue';
import SectionItem from '@/components/Dashboard/Analytics/SectionItem.vue';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'AnalyticsTab',
  components: {
    SectionItem,
    DashboardActionsDropdown
  },
  mixins: [modal, access],
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      sections: (state) => state.dashboard.analytics.ui_settings || []
    }),
    ...mapGetters({
      activeDashboard: 'dashboard/analytics/activeDashboard',
      widgets: 'dashboard/analytics/widgetByIdMap'
    }),
    isTabEmpty() {
      return !Object.keys(this.widgets).length;
    }
  },
  async created() {
    try {
      this.isLoading = true;

      await this.fetchWidgets();
      await this.fetchDashboardUiSettings();
    } finally {
      this.isLoading = false;
    }
  },
  beforeDestroy() {
    this.resetDashboard();
  },
  methods: {
    ...mapActions({
      fetchDashboardUiSettings: 'dashboard/analytics/fetchDashboardUiSettings',
      fetchWidgets: 'dashboard/analytics/fetchWidgets',
      resetDashboard: 'dashboard/analytics/resetDashboard'
    }),
    handleCrateWidget() {
      this.showModal(modalsId.CREATE_DASHBOARD_WIDGET, {
        dashboardId: this.activeDashboard.id
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/dashboard/analytics/analytics-tab";
</style>