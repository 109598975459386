<template>
  <div class="toolbar">
    <div class="toolbar__left-col">
      <div class="toolbar__option">
        <div class="toolbar__option-label">
          {{ $t('Dashboard.ToolBar.PeriodsCount') }}
        </div>
        <SlValidate
          v-slot="{ invalid }"
          key="periodsCount"
          vid="periodsCount"
          rules="required|between:1,200"
        >
          <SlControlInput
            v-model="periodsCount"
            :min="1"
            :max="200"
            :is-invalid="invalid"
          />
        </SlValidate>
      </div>
      <div class="toolbar__option">
        <SlDropdown
          :target-label="$t('Dashboard.ToolBar.ShowWidgets')"
          class="sl-multiple-select"
          placement="bottom-end"
        >
          <template #content>
            <SlDropdownOption
              v-for="option in tileItems"
              :key="option.value"
              :disabled="option.disabled"
            >
              <template #prepend>
                <SlCheckbox
                  :id="option.value"
                  :value="isTileSelected(option.value)"
                  :disabled="option.disabled"
                  @change="toggleVisibleTiles(option.value)"
                />
              </template>
              <label :for="option.value">
                {{ option.text }}
              </label>
            </SlDropdownOption>
          </template>
        </SlDropdown>
      </div>
      <div
        v-if="tileFormatsWithIcons.length > 1"
        class="toolbar__option"
      >
        <SlControl
          v-model="vModel"
          :options="tileFormatsWithIcons"
          options-icon-style-class="fill-off size-20"
        />
      </div>
    </div>
    <div class="toolbar__right-col">
      <div class="toolbar__legend">
        <span class="toolbar__legend-item">
          {{ $t('Dashboard.Legend.Base') }}
          <icon
            v-tooltip="{
              theme: 'dark',
              content: $t('Dashboard.Legend.InfoTooltip'),
              placement: 'right'
            }"
            data="@icons/info.svg"
            class="size-16 color-grey-50 color-white"
          />
        </span>
        <span class="toolbar__legend-item item__positive">
          {{ $t('Dashboard.Legend.Positive') }}
        </span>
        <span class="toolbar__legend-item item__negative">
          {{ $t('Dashboard.Legend.Negative') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { titleByKey } from '@/config/dashboard/dashboard.config';

export default {
  name: 'DashboardToolbar',
  props: {
    value: {
      type: String,
      required: true,
      default: ''
    },
    tileFormats: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState({
      selectTileNames: (state) => state.dashboard.monitoring.selectTileNames,
      visibleTileNames: (state) => state.dashboard.monitoring.visibleTileNames,
      tiles: (state) => state.dashboard.monitoring.tiles,
      periodsNumber: (state) => state.dashboard.monitoring.periodsNumber,
      isAvailable: (state) => state.dashboard.monitoring.isAvailable
    }),
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    selectedValue: {
      get() {
        return this.visibleTileNames;
      },
      set(val) {
        if (!val) {
          return;
        }

        this.setVisibleTileNames(val);
      }
    },
    tileItems() {
      return this.selectTileNames.map((item, index) => {
        let text = this.$t(item, { 1: '', 3: '' });
        const currency = this.tiles[index]?.currency;

        if (currency) {
          text += `, ${currency}`;
        }

        return {
          text,
          value: item,
          disabled: !this.isAvailable[item]
        };
      });
    },
    periodsCount: {
      get() {
        return this.periodsNumber;
      },
      set(value) {
        if (!value) {
          return;
        }

        this.setDashboardPeriods({ periods: value });
      }
    },
    tileFormatsWithIcons() {
      return this.tileFormats.reduce((acc, tileFormat) => {
        if (tileFormat.hidden) {
          return acc;
        }

        acc.push({
          ...tileFormat,
          icon: this.getIcon(tileFormat.value) || ''
        });

        return acc;
      }, []);
    }
  },
  watch: {
    selectedValue: {
      deep: true,
      handler(newValue, oldValue) {
        if (oldValue) {
          let result = '';
          let status = '';

          if (newValue.length < oldValue.length) {
            result = this.selectItemName(newValue, oldValue);
            status = false;
          } else {
            result = this.selectItemName(oldValue, newValue);
            status = true;
          }

          if (result) {
            this.handleTiles(result, status);
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      setDashboardPeriods: 'dashboard/monitoring/setPeriods',
      setVisibleTileNames: 'dashboard/monitoring/setVisibleTileNames',
      postDashboardTiles: 'dashboard/monitoring/postDashboardTiles'
    }),
    selectItemName(a, b) {
      const selectItem = b.find(el => a.indexOf(el) === -1);

      return selectItem ? this.getKeyByValue(titleByKey, selectItem) : '';
    },
    async handleTiles(title, status) {
      const payload = {
        tile: title,
        val: status
      };

      this.postDashboardTiles(payload);
    },
    getKeyByValue(object, value) {
      const index = Object.values(object).findIndex(el => el.code === value);

      return Object.keys(object)[index];
    },
    isTileSelected(tileName) {
      return this.selectedValue.includes(tileName);
    },
    toggleVisibleTiles(tileName) {
      if (this.isTileSelected(tileName)) {
        return this.selectedValue = this.selectedValue.filter(visibleTile => visibleTile && visibleTile !== tileName);
      }

      this.selectedValue = [...this.selectedValue, tileName];
    },
    getIcon(icon) {
      try {
        return require(`@icons/${icon}.svg`);
      } catch (e) {
        throw new Error(`Couldn't resolve icon "${icon}.svg"`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/toolbar/toolbar-dashboard.scss";
</style>
