<template>
  <div
    v-resize-observe="onResize"
    class="tile__wrapper"
  >
    <div
      class="tile__title"
      :class="{
        'tile__title--wrap': tileWidth < tileMinWidth
      }"
    >
      <div
        v-tooltip="getTooltip({
          content: tileTitle,
          placement: 'bottom'
        })"
        class="tile__title-content"
        @click="handleTileLinkClick"
      >
        {{ tileTitle }}
      </div>
      <div class="tile__period">
        <SlDropdown
          :target-label="selectedPeriod"
          auto-size
          class="sl-multiple-select"
          placement="bottom-end"
        >
          <template #content>
            <SlDropdownOption
              v-for="option in periodKeys"
              :key="option.value"
              select-close
              @select="changeSelect(option.value)"
            >
              {{ option.text }}
            </SlDropdownOption>
          </template>
        </SlDropdown>
      </div>
    </div>
    <div class="tile__content">
      <div class="tile__line-wrapper">
        <div class="tile__count">
          {{ cardCount + unit }}
        </div>
        <SlBadge
          v-if="isBadgeActive"
          class="tile__compared-data-wrapper"
          :variant="badgeColor"
        >
          <icon
            class="tile__arrow size-16"
            :class="[
              `tile__arrow_${badgeColor}`,
              `tile__arrow--${arrowDirection}`
            ]"
            data="@icon/dashboard_arrow.svg"
          />
          <div class="tile__compared-data">
            {{ comparedValue }}
          </div>
        </SlBadge>
      </div>
      <div class="tile__planning-items">
        {{ $t('Dashboard.Descriptions.Items') }} {{ planItems }}
      </div>
      <DashboardChart
        :chart-data="chartData"
        :active-date="activeDate"
        :get-compared-index="getComparedIndex"
        @change-date="compareData"
        @change-date-to-default="changeDefaultData"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DashboardChart from './DashboardChart.vue';
import { titleByKey, periodValues, tileKeys, chartColors } from '@/config/dashboard/dashboard.config';
import { formatNumber } from '@/helpers/format/formatNumberWithoutRemainder';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'DashboardTile',
  components: {
    DashboardChart
  },
  props: {
    title: {
      type: String,
      required: true
    },
    getComparedIndex: {
      type: Function,
      required: true
    },
    count: {
      type: String,
      required: true
    },
    period: {
      type: String,
      required: true
    },
    planItems: {
      type: Number,
      required: true
    },
    chartData: {
      type: Array,
      required: true
    },
    unit: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    lessIsBetter: Boolean
  },
  data() {
    return {
      getTooltip,
      cardCount: '',
      comparedValue: '',
      arrowDirection: 'up',
      badgeColor: chartColors.green,
      hideArrow: false,
      titleByKey,
      tileWidth: null,
      tileMinWidth: 400
    };
  },
  computed: {
    ...mapState ({
      activeDate: (state) => state.dashboard.monitoring.activeDate
    }),
    tileTitle() {
      let title = this.$t(this.title, { 1: '', 3: '' });

      if (this.currency) {
        title += `, ${this.currency}`;
      }

      return title;
    },
    periodKeys() {
      const periodItems = periodValues(this);

      return Object.keys(periodItems).map(key => ({
        text: periodItems[key],
        value: key
      }));
    },
    selectedPeriod() {
      const periodItems = periodValues(this);

      return periodItems[this.period];
    },
    isBadgeActive() {
      return !this.hideArrow && this.badgeColor;
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.handleActiveData();
      }
    }
  },
  mounted() {
    this.handleActiveData();
  },
  methods: {
    ...mapActions({
      openTileLink: 'dashboard/monitoring/openTileLink',
      postDashboardPeriods: 'dashboard/monitoring/postDashboardPeriods'
    }),
    handleActiveData() {
      this.cardCount = this.count;
      this.comparedValue = this.chartData[this.activeDate].displayData.value;

      if ([' ', '—'].includes(this.comparedValue)) {
        this.hideArrow = true;
      } else {
        this.hideArrow = false;
        this.badgeColor = this.chartData[this.activeDate].displayData.arrowColor;
        this.arrowDirection = this.chartData[this.activeDate].displayData.arrowDirection;
      }
    },
    compareData(el) {
      if (this.type === tileKeys.MARGIN) {
        this.cardCount = Math.round(el.value * 10000) / 100;
      } else if (this.type === tileKeys.TURNOVER) {
        this.cardCount = Math.round(100 * el.value) / 100;
      } else {
        this.cardCount = formatNumber(el.value);
      }

      if ([' ', '—'].includes(this.comparedValue)) {
        this.hideArrow = true;
      } else {
        this.hideArrow = false;
        this.comparedValue = el.displayData.value;
        this.arrowDirection = el.displayData.arrowDirection;
        this.badgeColor = el.displayData.arrowColor;
      }
    },
    changeDefaultData() {
      this.handleActiveData();
    },
    changeSelect(val) {
      const index = Object
        .values(titleByKey)
        .findIndex(el => el.code === this.title);
      const payload = {
        tile: Object.keys(titleByKey)[index],
        val
      };

      this.postDashboardPeriods(payload);
    },
    handleTileLinkClick() {
      this.openTileLink({
        type: this.type,
        lessIsBetter: this.lessIsBetter,
        index: this.type === tileKeys.PURCHASE_VALUE ? '0' : null
      });
    },
    onResize(el) {
      this.tileWidth = el.offsetWidth;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/dashboard/monitoring/tile";
</style>
