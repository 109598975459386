var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlModal',{attrs:{"id":_vm.id,"width":720,"title":_vm.$t('Web.Dashboard.Widget.ModalTitle'),"loading":_vm.isLoading,"updating":_vm.isUpdating,"persistent":""},on:{"on-enter":_vm.handleSubmit,"created":_vm.onCreated,"show":_vm.onModalShow,"hide":_vm.onModalHide},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('SlModalFooter',[_c('SlButton',{attrs:{"variant":"secondary","color":"grey"},on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.$t('Common.Cancel'))+" ")]),_c('SlButton',{attrs:{"disabled":_vm.isUpdating},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.submitButtonLabel)+" ")])],1)]},proxy:true}])},[(_vm.config)?_c('ValidationObserver',{ref:"observer",staticClass:"modal-content create-widget"},[_c('div',{staticClass:"create-widget__block"},[_c('SlValidate',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.Dashboard.Widget.Name'),"is-invalid":invalid,"required":""},model:{value:(_vm.widgetName),callback:function ($$v) {_vm.widgetName=$$v},expression:"widgetName"}})]}}],null,false,4015638626)}),(_vm.config.widgetType.enabled)?_c('SlSelect',{attrs:{"options":_vm.config.widgetType.selection,"select-label":_vm.config.widgetType.name},on:{"input":function (value) { return _vm.updateWidget({
          block: _vm.blockTypes.WIDGET_TYPE,
          value: value
        }); }},model:{value:(_vm.config.widgetType.val),callback:function ($$v) {_vm.$set(_vm.config.widgetType, "val", $$v)},expression:"config.widgetType.val"}}):_vm._e()],1),(_vm.config.reportConfig.length)?[_c('div',{staticClass:"create-widget__header"},[_vm._v(" "+_vm._s(_vm.$t('Web.Dashboard.Widget.ReportConfigurations'))+" ")]),_vm._l((_vm.config.reportConfig),function(reportConfig,configIndex){return _c('div',{key:configIndex,staticClass:"create-widget__block create-widget__block--row",class:{
          'create-widget__block--compared': _vm.isComparedBlock(configIndex)
        }},[_vm._l((reportConfig),function(item){return _c('div',{key:item.requestKey,class:['create-widget__control', {
            'w-50': _vm.isSplitControls(item)
          }]},[(item.type === _vm.controlType.SELECT)?_c('SlSelect',{attrs:{"options":item.selection,"select-label":item.name},on:{"input":function (value) { return _vm.updateWidget({
              block: _vm.blockTypes.REPORT_CONFIG,
              index: configIndex,
              requestKey: item.requestKey,
              value: value
            }); }},model:{value:(item.val),callback:function ($$v) {_vm.$set(item, "val", $$v)},expression:"item.val"}}):(item.type === _vm.controlType.CHECKBOX)?_c('SlCheckbox',{attrs:{"label":item.name,"disabled":!item.enabled},on:{"change":function (value) { return _vm.updateWidget({
              block: _vm.blockTypes.REPORT_CONFIG,
              index: configIndex,
              requestKey: item.requestKey,
              value: value
            }); }},model:{value:(item.val),callback:function ($$v) {_vm.$set(item, "val", $$v)},expression:"item.val"}}):(item.type === _vm.controlType.NUMBER_INPUT)?_c('SlValidate',{attrs:{"rules":("required|between:" + (item.min) + "," + (item.max)),"vid":((item.requestKey) + "-" + configIndex)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var invalid = ref.invalid;
            var validate = ref.validate;
return [_c('SlControlInput',{attrs:{"label":item.name,"min":item.min,"max":item.max,"disabled":!item.enabled,"is-invalid":invalid},on:{"input":function (value) { return _vm.updateHistoricalPeriod({
                block: _vm.blockTypes.REPORT_CONFIG,
                index: configIndex,
                requestKey: item.requestKey,
                value: value,
                validate: validate
              }); }},model:{value:(item.val),callback:function ($$v) {_vm.$set(item, "val", $$v)},expression:"item.val"}})]}}],null,true)}):(item.type === _vm.controlType.CONTROL)?_c('SlControl',{attrs:{"label":item.name,"options":_vm.getChartControlOptions(item.selection),"options-icon-style-class":"fill-off size-20 color-grey-60"},on:{"input":function (value) { return _vm.updateWidget({
              block: _vm.blockTypes.REPORT_CONFIG,
              index: configIndex,
              requestKey: item.requestKey,
              value: value
            }); }},model:{value:(item.val),callback:function ($$v) {_vm.$set(item, "val", $$v)},expression:"item.val"}}):_vm._e()],1)}),_c('div',{staticClass:"create-widget__block-divider"},[_vm._v(" "+_vm._s(_vm.$t('Web.Dashboard.Widget.Compare'))+" ")])],2)})]:_vm._e(),(_vm.config.aggregation)?[_c('div',{staticClass:"create-widget__header"},[_vm._v(" "+_vm._s(_vm.$t('Web.Dashboard.Widget.Aggregation'))+" ")]),_c('div',{staticClass:"create-widget__block"},[_c('div',{staticClass:"create-widget__control"},[(_vm.config.aggregation.type === _vm.controlType.COMBOBOX)?_c('SlSelect',{staticClass:"widget-aggregation",attrs:{"value":_vm.config.aggregation.val,"options":_vm.config.aggregation.selection,"select-label":_vm.config.aggregation.name,"close-on-select":false,"allow-empty":"","return-object":"","multiple":""},on:{"input":_vm.updateAggregation},scopedSlots:_vm._u([{key:"selection",fn:function(){return [_vm._v(" "+_vm._s(_vm.getAggregationLabel(_vm.config.aggregation))+" ")]},proxy:true},{key:"selectOption",fn:function(ref){
            var option = ref.option;
return [_c('div',{staticClass:"widget-aggregation__option"},[(!option.noCheckbox)?_c('SlCheckbox',{attrs:{"value":option.status}}):_vm._e(),_c('div',{staticClass:"widget-aggregation__option-label"},[_vm._v(" "+_vm._s(option.label)+" ")])],1)]}}],null,false,2526488001)}):_vm._e()],1)])]:_vm._e(),(_vm.config.timeView)?[_c('div',{staticClass:"create-widget__header"},[_vm._v(" "+_vm._s(_vm.config.timeView.name)+" ")]),_c('div',{staticClass:"create-widget__block"},[_c('div',{staticClass:"create-widget__control"},[_c('SlSelect',{attrs:{"options":_vm.config.timeView.selection},on:{"input":function (value) { return _vm.updateWidget({
              block: _vm.blockTypes.TIME_VIEW,
              requestKey: _vm.config.timeView.requestKey,
              value: value
            }); }},model:{value:(_vm.config.timeView.val),callback:function ($$v) {_vm.$set(_vm.config.timeView, "val", $$v)},expression:"config.timeView.val"}})],1)])]:_vm._e(),(_vm.config.sorting)?[_c('div',{staticClass:"create-widget__header"},[_vm._v(" "+_vm._s(_vm.config.sorting.name)+" ")]),_c('div',{staticClass:"create-widget__block"},[_c('div',{staticClass:"create-widget__control"},[_c('SlSelect',{attrs:{"options":_vm.config.sorting.selection},on:{"input":function (value) { return _vm.updateWidget({
              block: _vm.blockTypes.SORTING,
              value: value
            }); }},model:{value:(_vm.config.sorting.val),callback:function ($$v) {_vm.$set(_vm.config.sorting, "val", $$v)},expression:"config.sorting.val"}})],1)])]:_vm._e(),[_c('div',{staticClass:"create-widget__header"},[_vm._v(" "+_vm._s(_vm.$t('Web.Dashboard.Widget.Filter'))+" ")]),_c('div',{staticClass:"create-widget__block create-widget__block--filter"},[(!_vm.isFilterExist)?_c('SlButton',{attrs:{"variant":"secondary","color":"grey"},on:{"click":_vm.handleAddFilter},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-16 color-grey-80",attrs:{"data":require("@icons/plus.svg")}})]},proxy:true}],null,false,2739651316)},[_vm._v(" "+_vm._s(_vm.$t('Web.Dashboard.Widget.AddFilter'))+" ")]):_c('SlButton',{attrs:{"variant":"secondary","color":"grey"},on:{"click":_vm.handleEditFilter},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-16 color-grey-80",attrs:{"data":require("@icons/pen.svg")}})]},proxy:true}],null,false,4192804597)},[_vm._v(" "+_vm._s(_vm.$t('Web.Dashboard.Widget.EditFilter'))+" ")]),(_vm.isFilterExist)?_c('SlButton',{attrs:{"variant":"secondary","color":"grey"},on:{"click":_vm.handleDeleteFilter},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-16 color-grey-80",attrs:{"data":require("@icons/trash.svg")}})]},proxy:true}],null,false,3966973714)},[_vm._v(" "+_vm._s(_vm.$t('Web.Dashboard.Widget.DeleteFilter'))+" ")]):_vm._e()],1)]],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }